import React, { FunctionComponent, useEffect, useState } from 'react';
import classNames from "classnames";
import FlagSk from "../../assets/icons/flag_sk.svg";
import FlagUs from "../../assets/icons/flag_us.svg";
import { MenuItem } from "./Navigation";
import Link from "gatsby-link";
import Dropdown from "../Dropdown";
import useComponentVisible from "../../hooks/componentVisible";
import { createSlug as createSlugUtil } from "../../utils";
import LocalizedLink from '../LocalizedLink';
import useMobile from "../../hooks/useMobile";

type NavigationMenuItemProps = {
    item: MenuItem,
};

const createSlug = (name: string, parentName: string, link?: string,): string => {
    if (link) {
        return link
    }

    const parentSlug = createSlugUtil(parentName)
    const slug = createSlugUtil(name)
    return `/${parentSlug}/${slug}`
}

const getClassNames = (isMobile: boolean, isComponentVisible: boolean) => {
    if (isMobile) {
        return classNames(
            isComponentVisible ? '' : 'hidden',
        )
    }
    return classNames(
        "origin-top-right absolute z-50 w-80 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none",
        isComponentVisible ? '' : 'hidden',
    )
}

const NavigationMenuItem: FunctionComponent<NavigationMenuItemProps> = ({ item }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const isMobile = useMobile();

    const handleMouseEnter = () => {
        if (!isMobile) {
            setIsComponentVisible(true)
        }
    }

    const handleMouseLeave = () => {
        if (!isMobile) {
            setIsComponentVisible(false)
        }
    }

    const handleOnClick = () => {
        if (isMobile) {
            setIsComponentVisible(old => !old)
            return
        }
        setIsComponentVisible(false)
    }

    return (
        <div className={'relative ' + isMobile ? 'mt-4' : ''} ref={ref}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div onClick={handleOnClick}>
                {item.link ? (
                    <LocalizedLink to={item.link} className={'cursor-pointer text-ink-100 font-semibold'}>
                        {item.label}
                    </LocalizedLink>
                ) : (
                    <span
                        className={'cursor-pointer text-ink-100 font-semibold'}
                    >
                        {item.label}
                    </span>
                )}
            </div>

            {item.children ? (
                <div
                    className={getClassNames(isMobile, isComponentVisible)}
                    role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        <ul>
                            {item.children.map((menu, index) => (
                                <li key={`navigation-menu-item-${menu.label}`}>
                                    <LocalizedLink
                                        onClick={() => setIsComponentVisible(false)}
                                        to={createSlug(menu.label, item.label, menu.link)}
                                        className={'block text-ink-60 font-medium hover:bg-brand-5 px-5 py-2.5 cursor-pointer hover:text-black'}
                                    >
                                        {menu.label}
                                        {menu.hint ? (
                                            <p className={'text-sm text-ink-60 font-normal'}>{menu.hint}</p>
                                        ) : null}
                                    </LocalizedLink>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
            ) : null}

        </div>
    );
};

export default NavigationMenuItem;
