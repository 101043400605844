import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '../hooks/locale'

function useTranslations() {
    // Grab the locale (passed through context) from the Locale Provider
    // through useLocale() hook
    const { locale } = useLocale()
    // Query the JSON files in <rootDir>/i18n/translations
    const { rawData } = useStaticQuery(query)

    // Simplify the response from GraphQL
    const simplified = rawData.edges.map(item => {
        return {
            name: item.node.name,
            translations: item.node.translations,
        }
    })

    // Only return translations for the current locale
    const { translations } = simplified.filter(lang => lang.name === locale)[0]

    return translations
}

export default useTranslations

const query = graphql`
    query useTranslations {
        rawData: allFile(
            filter: { sourceInstanceName: { eq: "translations" } }
        ) {
            edges {
                node {
                    name
                    translations: childTranslationsJson {
                        home
                        getStarted
                        hello
                        subline
                        latestPosts
                        category
                        allPosts
                        toRead

                        next
                        prev
                        of

                        button

                        aboutProject
                        seeMorePWA
                        maintainedBy
                        contributeMessage

                        actions {
                            start
                            learn
                            send
                        }

                        form {
                            placeholder {
                                email
                                fullName
                                phone
                                message
                            }
                            hint {
                                phone
                            }
                            label {
                                email
                                fullName
                                phone
                                message
                            }
                            error {
                                email {
                                    invalid
                                }
                                fullName {
                                    short
                                    long
                                }
                                phone {
                                    matches
                                }
                            }
                            required
                        }

                        shopPolicy

                        orderForm {
                            title
                            text
                            success {
                                text1
                                text2
                                start
                            }
                        }

                        contactForm {
                            title
                            heading
                            success {
                                text1
                                text2
                                start
                            }
                        }

                        promoSection {
                            title1
                            title2
                            start
                            contact
                        }

                        faqs {
                            subTitle
                            title
                            all
                            items {
                                title
                                text
                            }
                        }

                        pages {
                            blog {
                                search
                                categories
                                featuredPosts
                                actions {
                                    loadMore
                                    continueReading
                                }
                                similarPosts
                                all
                            }

                            index {
                                hero {
                                    title
                                    description
                                    actions {
                                        start
                                        learn
                                    }
                                }

                                offers {
                                    subTitle
                                    title
                                    items {
                                        title
                                        image
                                        description
                                        links {
                                            title
                                            link
                                        }
                                    }
                                }

                                relax {
                                    subTitle
                                    title
                                    description
                                    contact
                                }

                                whyUs {
                                    title
                                    establishedCompanies
                                    hoursSpent
                                    documentsSigned
                                    customers
                                }

                                promo {
                                    title1
                                    title2
                                    start
                                    contact
                                }
                            }

                            sro {
                                heroTitle
                                heroDescription
                                actions {
                                    setup
                                    learn
                                }

                                offers {
                                    title
                                    description
                                    items {
                                        title
                                        text
                                    }
                                }

                                price {
                                    startAt
                                    start
                                    fee
                                    h2
                                    disclaimer
                                }

                                easy {
                                    subTitle
                                    title
                                    setup
                                    items {
                                        title
                                        text
                                    }
                                }

                                promo {
                                    title1
                                    title2
                                    start
                                    contact
                                }
                            }

                            freelance {
                                heroTitle
                                heroDescription
                                actions {
                                    setup
                                    learn
                                }

                                offers {
                                    title
                                    description
                                    items {
                                        title
                                        text
                                    }
                                }

                                price {
                                    startAt
                                    start
                                    fee
                                    h2
                                    disclaimer
                                }

                                easy {
                                    subTitle
                                    title
                                    setup
                                    items {
                                        title
                                        text
                                    }
                                }

                                promo {
                                    title1
                                    title2
                                    start
                                    contact
                                }
                            }

                            faqs {
                                title
                                items {
                                    title
                                    key
                                    items {
                                        title
                                        text
                                    }
                                }
                            }

                            signpost {
                                title
                                subTitle
                                description
                                items {
                                    title
                                    links {
                                        link
                                        title
                                        description
                                    }
                                }
                            }

                            policies {
                                headline
                                article
                                items {
                                    title
                                    children {
                                        text
                                        children {
                                            text
                                        }
                                    }
                                }
                            }

                            contact {
                                title
                                subTitle
                                map
                                tax_id
                                business_id
                                vat
                            }
                        }
                    }
                }
            }
        }
    }
`
