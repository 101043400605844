// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-freelance-tsx": () => import("./../../../src/pages/freelance.tsx" /* webpackChunkName: "component---src-pages-freelance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-form-tsx": () => import("./../../../src/pages/order-form.tsx" /* webpackChunkName: "component---src-pages-order-form-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-sro-signpost-tsx": () => import("./../../../src/pages/sro-signpost.tsx" /* webpackChunkName: "component---src-pages-sro-signpost-tsx" */),
  "component---src-pages-sro-tsx": () => import("./../../../src/pages/sro.tsx" /* webpackChunkName: "component---src-pages-sro-tsx" */),
  "component---src-pages-sro-video-fold-tsx": () => import("./../../../src/pages/sro-video-fold.tsx" /* webpackChunkName: "component---src-pages-sro-video-fold-tsx" */),
  "component---src-templates-blogs-by-category-tsx": () => import("./../../../src/templates/blogsByCategory.tsx" /* webpackChunkName: "component---src-templates-blogs-by-category-tsx" */),
  "component---src-templates-detail-tsx": () => import("./../../../src/templates/detail.tsx" /* webpackChunkName: "component---src-templates-detail-tsx" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

