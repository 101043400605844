import React from 'react';
// import useTranslations from '../useTranslations';
// import Button from "./Button/Button";
// import { Link } from 'gatsby';
import Link from "gatsby-link" // not error
import Logo from "../../assets/images/logo.svg";
import Button from "../Button/Button";
import NavigationMenuItem from "./NavigationMenuItem";
import useMenu from "../../hooks/useMenu";
import useMobile from "../../hooks/useMobile";
import LocalizedLink from '../LocalizedLink';
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";

export type MenuItem = {
    label: string,
    link?: string,
    hint?: string,
    children?: MenuItem[],
}


const Navigation = (props) => {
    const isMobile = useMobile();

    if (isMobile) {
        return (<NavigationMobile {...props} />)
    }

    return (<NavigationDesktop {...props} />)
};

export default Navigation;
