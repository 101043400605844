export const createSlug = (raw_slug: string): string => {
    if (!raw_slug) {
        return 'N/A'
    }

    return raw_slug
        .toString()
        .toLowerCase()
        .normalize('NFKD')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '')
}
