import React, { FunctionComponent, useState } from 'react';
import classNames from "classnames";
// @ts-ignore
import Arrow from '../../assets/icons/ico_rightarrow.svg';
import ArrowWhite from '../../assets/icons/ico_rightarrow_white.svg';
import ArrowBlue from '../../assets/icons/ico_rightarrow_blue.svg';
import ArrowInk60 from '../../assets/icons/ico_rightarrow_ink_60.svg';

interface OwnProps extends React.ButtonHTMLAttributes<HTMLElement> {
    disabled?: boolean;
    block?: boolean;
    // active?: boolean;
    chevron?: boolean;
    variant?: "primary" | "secondary" | "border" | "undefined";
    link?: boolean;
    whiteChevron?: boolean;
    chevronVariant?: "black" | "white" | "blue" | "ink_60",
    defaultStyle?: string
}

type Props = OwnProps;

const chevronVariants = {
    black: Arrow,
    white: ArrowWhite,
    blue: ArrowBlue,
    ink_60: ArrowInk60
}

const getChevronVariantByKey = (key?: string) => {
    if (!key || !chevronVariants[key]) {
        return chevronVariants.black
    }
    return chevronVariants[key]
}

const Button: FunctionComponent<Props> = ({
    className,
    variant = "primary",
    disabled = false,
    chevron = true,
    link = false,
    whiteChevron = false,
    chevronVariant = "black",
    defaultStyle= 'font-serif inline-flex items-center font-semibold rounded-full',
    ...props
}) => {
    const [Arrow, setArrow] = useState(getChevronVariantByKey(chevronVariant))

    const classes = classNames(
        className,
        defaultStyle,
        variant === "primary" && 'px-5 py-2.5 text-white bg-blue-100 hover:bg-blue-110 focus:outline-none focus:ring-2 focus:ring-blue-100',
        variant === "secondary" && 'px-5 py-2.5 text-white bg-brand-100 hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-brand-10',
        variant === "border" && 'px-4 py-1.5 border-2 border-black text-black hover:bg-black hover:text-white focus:text-white focus:outline-none focus:bg-ink-60 focus:ring-1 focus:ring-black',
        variant === "undefined" && 'px-4 py-1.5 border-0 focus:outline-none',
        disabled && 'opacity-50 cursor-not-allowed',
    );

    const handleMouseEnter = (e) => {
        if (variant === "border") {
            setArrow(getChevronVariantByKey('white'))
        }
    }

    const handleMouseLeave = (e) => {
        if (variant === "border") {
            setArrow(getChevronVariantByKey(chevronVariant))
        }
    }

    return (
        <button {...props} className={classes} disabled={disabled} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.children}
            {chevron ? <img className={'w-6 h-6 ml-1'} style={{ marginRight: '-3px' }} src={Arrow} /> : null}
        </button>
    );
};

export default Button;
