import React, { useEffect, useState } from 'react'

const useCheckMobileScreen = () => {
    const isBrowser = () => typeof window !== 'undefined'

    const innerWidth = isBrowser() ? window.innerWidth : 0
    const [width, setWidth] = useState(innerWidth)

    const handleWindowSizeChange = () => {
        setWidth(innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    return width <= 768
}

export default useCheckMobileScreen
