import React from 'react';
// import useTranslations from '../useTranslations';
// import Button from "./Button/Button";
// import { Link } from 'gatsby';
import Link from "gatsby-link" // not error
import Logo from "../../assets/images/logo.svg";
import Button from "../Button/Button";
import NavigationMenuItem from "./NavigationMenuItem";
import useMenu from "../../hooks/useMenu";
import LocalizedLink from '../LocalizedLink';
import useTranslations from '../useTranslations';
import { useLocation } from '@reach/router';

export type MenuItem = {
    label: string,
    link?: string,
    hint?: string,
    children?: MenuItem[],
}


const NavigationDesktop = (props) => {
    const { mainMenuItems, topMenuItems } = useMenu();
    const { getStarted } = useTranslations()
    const location = useLocation();
    const isOrderFormPage = location.pathname === '/order-form';

    return (
        <div {...props}>
            <div className={'container'}>
                <div className={'flex justify-end items-center py-4 border-b border-ink-10 flex-wrap lg:flex-nowrap'}>
                    {/* <LanguageSwitcher /> */}
                    <div className={'flex space-x-8'}>
                        {topMenuItems.map((menu, index) => (
                            <LocalizedLink
                                to={menu.link}
                                key={`${menu.link}${index}`}
                                className={'text-sm text-ink-60 opacity-80'}
                            >
                                {menu.label}
                            </LocalizedLink>
                        ))}
                    </div>
                </div>
                <div className={'flex lg:py-6 items-center justify-between flex-wrap lg:flex-nowrap'}>
                    <div>
                        <LocalizedLink to={'/'}>
                            <img src={Logo} alt="" className={'w-160 h-60'} />
                        </LocalizedLink>
                    </div>
                    <div className={'flex space-x-8'}>
                        {mainMenuItems.map((menu, index) => (
                            <NavigationMenuItem item={menu} key={`${menu.link}${index}`} />
                        ))}
                    </div>
                    <div>
                        {!isOrderFormPage && <LocalizedLink to={'/order-form'}>
                            <Button variant={'border'}>
                                {getStarted}
                            </Button>
                        </LocalizedLink>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationDesktop;
