import { useLocale } from "../hooks/locale";
import React, { Component, FunctionComponent, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import LocaleContext from "../localeContext";

import setupI18next from "./i18nSetup";

const withI18n = (Comp): FunctionComponent => (props: any) => {
    const { pageContext } = props;
    const i18n = setupI18next();
    const { changeLocale } = useLocale()

    const changeLanguage = () => {
        addResources(pageContext);
        changeLocale(pageContext.locale)
        // i18n.changeLanguage(pageContext.locale);
    };
    // @see https://www.i18next.com/overview/api#resource-handling
    // `translation` is the default NS we use consistently.
    const addResources = pageContext => {
        if (
            pageContext &&
            pageContext.localeResources &&
            pageContext.localeResources.translation
        ) {
            const {
                locale: lng,
                localeResources: { translation }
            } = pageContext;

            if (!i18n.hasResourceBundle(lng, "translation")) {
                i18n.addResourceBundle(lng, "translation", translation);
            }
        }
    };

    useEffect(() => {
        changeLanguage();
    }, [pageContext]);

    return (
        <I18nextProvider i18n={i18n}>
            <Comp {...props} />
        </I18nextProvider>
    );
}

export default withI18n;
