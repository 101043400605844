import React from 'react';
// import useTranslations from '../useTranslations';
// import Button from "./Button/Button";
// import { Link } from 'gatsby';
import Link from "gatsby-link" // not error
import Logo from "../../assets/images/logo.svg";
import Button from "../Button/Button";
import NavigationMenuItem from "./NavigationMenuItem";
import useMenu from "../../hooks/useMenu";
import LocalizedLink from '../LocalizedLink';
import Hamburger from 'hamburger-react'

export type MenuItem = {
    label: string,
    link?: string,
    hint?: string,
    children?: MenuItem[],
}


const NavigationMobile = (props) => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const { mainMenuItems, topMenuItems } = useMenu();

    return (
        <div {...props}>
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-pink-500 mb-3">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <div>
                            <LocalizedLink to={'/'}>
                                <img src={Logo} alt="" className={'w-160 h-60'} />
                            </LocalizedLink>
                        </div>
                        <Hamburger
                            onToggle={navbarOpen => {
                            setNavbarOpen(navbarOpen)
                        }} />

                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center justify-center flex-col" +
                            (navbarOpen ? " flex" : " hidden")
                        }
                        id="example-navbar-danger"
                    >
                        <div className={'flex flex-col text-center border-b border-ink-20 pb-4'}>
                            {mainMenuItems.map((menu, index) => (
                                <NavigationMenuItem item={menu} key={`${menu.link}${index}`} />
                            ))}
                        </div>
                        <div className={'flex flex-col text-center mb-4 border-b border-ink-20 pb-4'}>
                            {topMenuItems.map((menu, index) => (
                                <LocalizedLink
                                    to={menu.link}
                                    key={`${menu.link}${index}`}
                                    className={'text-sm text-ink-100 mt-4'}
                                >
                                    {menu.label}
                                </LocalizedLink>
                            ))}
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavigationMobile;
