import React, { FunctionComponent } from 'react';
import { MenuItem } from "./Navigation/Navigation";
import Link from "gatsby-link";
import Logo from "../assets/images/logo.svg";
import FacebookIcon from "../assets/icons/ico_facebook.svg";
import YoutubeIcon from "../assets/icons/ico_YouTube.svg";
import LinkedInIcon from "../assets/icons/ico_LinkedIn.svg";
import EnvelopeIcon from "../assets/icons/ico_envelope_blue.svg";
import useTranslations from './useTranslations';
import LocalizedLink from './LocalizedLink';

interface OwnProps { }

type Props = OwnProps;

const FooterMenu: MenuItem[] = [
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
            {
                label: 'Contract law',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
            {
                label: 'Contract law',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
        ]
    },
];

const FooterMenuColumn = ({ item }) => {
    const { title, links = [] } = item;


    return (
        <div className={'space-y-4'}>
            <h5 className={'text-lg font-semibold text-center sm:text-left'}>{title}</h5>
            <ul className={'space-y-3'}>
                {links.map((menu, index) => (
                    <li className='text-center sm:text-left' key={`${menu.link}-FooterMenuColumn-${index}`}>
                        <LocalizedLink
                            to={menu.link}
                            key={`${menu.link}${index}`}
                            className={'text-ink-60'}
                        >
                            {menu.title}
                        </LocalizedLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const Footer: FunctionComponent<Props> = () => {
    const { pages: { index }, shopPolicy } = useTranslations()

    return (
        <footer>
            <div className={'container'}>
                {/* <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 border-b border-ink-10 py-14 justify-items-center sm:justify-items-start'}>
                    {index.offers.items.map((offer, index) => (
                        <FooterMenuColumn key={`footer-menu-colum-${index}`} item={offer} />
                    ))}
                </div> */}
                <div className={'flex justify-center sm:justify-between items-center py-10 w-full flex-wrap sm:flex-nowrap flex-col sm:flex-row'}>
                    <div>
                        <LocalizedLink to={'/'}>
                            <img src={Logo} alt="" className={'w-160 h-60'} />
                        </LocalizedLink>
                    </div>
                    <div className={'lg:ml-16 mt-8 lg:mt-0'}>
                        <p className={'flex text-blue-100 space-x-3'}>
                            <img src={EnvelopeIcon} alt="" className={'w-6 h-6 text-blue-100'} />
                            <a href="mailto:legalia@legalia.sk" className={'underline'}>legalia@legalia.sk</a>
                        </p>
                    </div>
                    {/* <div className={'lg:ml-16 mt-8 lg:mt-0'}>
                        <LocalizedLink to={'/policies'} className={'link'}>{shopPolicy}</LocalizedLink>
                    </div> */}
                    {/* <div className={'flex space-x-8 flex-1 justify-center lg:justify-end mt-8 lg:mt-0'}>
                        <img srcSet={FacebookIcon} src={FacebookIcon} alt="" className={'w-6 h-6'} />
                        <img srcSet={LinkedInIcon} src={LinkedInIcon} alt="" className={'w-6 h-6'} />
                        <img srcSet={YoutubeIcon} src={YoutubeIcon} alt="" className={'w-6 h-6'} />
                    </div> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
